<template>
  <v-container v-if="enrolledPatientDictionary" fluid class="pa-0">
    <portal to="appBarContentLeft">
      <v-row class="flex-nowrap" align="center" justify="space-between" no-gutters>
        <span style="font-weight: bold">
          {{ org.name }}
        </span>
      </v-row>
    </portal>
    <portal to="appBarContentRight">
      <v-row class="flex-nowrap" align="center" justify="end" no-gutters>
        <v-btn depressed @click="exportTheList()">Export Device List</v-btn>
      </v-row>
    </portal>
    <!-- <v-row v-for="(device, index) in orgDevices" :key="index" no-gutters>
      {{ index }}{{ device.kind }}{{ device.deviceRefId }}{{ device.live }}{{ device.patientId }}
    </v-row> -->

    <v-data-table
      dense
      fixed-header
      height="calc(100vh - 64px)"
      :headers="headers"
      hide-default-footer
      :items="orgDevicesCorrelatedWithEnrolledPatients"
      items-per-page="500"
      @click:row="handleClick"
      class="row-pointer grey lighten-4"
      style="background-color: transparent"
    >
      <!-- <template v-slot:item.lastName="{ item }">
            <v-row justify="start" no-gutters>
              <span class="font-weight-regular text-body-2">
                {{ item.firstName }}
                <span class="font-weight-bold text-body-2">{{ item.lastName }}</span>
                <span class="text--secondary ml-1" v-if="item.org.patientId">{{ item.org.patientId }}</span>
              </span>
            </v-row>
          </template> -->

      <template v-slot:item.age="{ item }">
        <span
          ><span>{{ item.age }}</span>
        </span>
      </template>

      <template v-slot:item.paid="{ item }">
        <span><span v-if="item.age > 0 && item.age < 13">$$</span> </span>
      </template>

      <template v-slot:item.enrolled="{ item }">
        <v-row v-if="item.enrolled" justify="center" no-gutters>
          <span class="font-weight-regular text-body-2">
            {{ item.enrolledHumanReadable }}
            <!-- {{ enrolledPatientDictionary[item.patientId].firstName.charAt(0) }} -->
            <!-- <span class="font-weight-bold text-body-2">{{ item.lastName }}</span>
                <span class="text--secondary ml-1" v-if="item.org.patientId">{{ item.org.patientId }}</span> -->
          </span>
        </v-row>
        <v-row v-else justify="center" no-gutters> - </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'
import { createDeviceCSV } from '@/helpers/csv'

export default {
  components: {},
  data() {
    return {
      selectedCategory: 0,
      mode: 0,
    }
  },
  computed: {
    ...mapState('rpm', ['orgDevices']),
    ...mapState('auth', ['user']),
    ...mapState('org', ['org', 'enrolledPatients', 'patientsDict']),
    headers() {
      let headers = [
        {
          text: 'Device Kind',
          align: 'start',
          sortable: true,
          value: 'kind',
        },
        {
          text: 'Device ID',
          align: 'start',
          sortable: true,
          value: 'deviceRefId',
        },
        {
          text: 'Device Age (mo)',
          align: 'center',
          sortable: true,
          value: 'age',
        },

        {
          text: 'Cash',
          align: 'center',
          sortable: true,
          value: 'paid',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Assignment',
          align: 'center',
          sortable: true,
          value: 'enrolled',
        },
        {
          text: 'Patient ID',
          align: 'start',
          sortable: true,
          value: 'orgID',
        },
        {
          text: 'Patient First',
          align: 'start',
          sortable: true,
          value: 'firstName',
        },
        {
          text: 'Patient Last',
          align: 'start',
          sortable: true,
          value: 'lastName',
        },
      ]
      return headers
    },
    isViewingUnassigned() {
      return true
    },
    enrolledPatientDictionary() {
      let dict = {}

      this.enrolledPatients.forEach(patient => {
        dict[patient.id] = patient
      })
      return dict
    },
    orgDevicesCorrelatedWithEnrolledPatients() {
      let rawDevices = this.orgDevices

      rawDevices.forEach(device => {
        device.status = '-'
        device.age = '-'
        device.orgID = '-'
        device.firstName = '-'
        device.lastName = '-'
        if (device.live && this.enrolledPatientDictionary[device.patientId]) {
          device.firstName = this.enrolledPatientDictionary[device.patientId].firstName
          device.lastName = this.enrolledPatientDictionary[device.patientId].lastName
          device.orgID = this.enrolledPatientDictionary[device.patientId].org.patientId
          device.enrolled = Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start)
          device.enrolledHumanReadable = dayjs(
            Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start)
          ).format('MM/DD/YYYY')
          device.status = 'Assigned'
          const date1 = dayjs()
          device.age = date1.diff(
            Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start),
            'month'
          )
          device.assignmentEnrolled
          device.patientDetail =
            this.enrolledPatientDictionary[device.patientId].firstName.charAt(0) +
            '. ' +
            this.enrolledPatientDictionary[device.patientId].lastName
        } else if (device.live && !this.enrolledPatientDictionary[device.patientId]) {
          device.patientDetail = 'Assigned to an unenrolled Patient'
          device.issue = true
        } else if (device.live) {
          device.patientDetail = device.patientId
        }
      })
      return rawDevices
    },
    arrayToUse() {
      let live = this.orgDevicesCorrelatedWithEnrolledPatients.filter(function (e) {
        return e.live
      })

      let notLive = this.orgDevicesCorrelatedWithEnrolledPatients.filter(function (e) {
        return !e.live
      })

      return [
        {
          count: live.length,
          color: '#1A76F2',
          title: 'Assigned Devices',
          list: live,
          fullTitle: 'assigned',
          icon: 'mdi-thumb-up',
          total: Math.floor((live.length / this.orgDevicesCorrelatedWithEnrolledPatients.length) * 100),
          description: 'Devices currently assigned to patients',
        },
        {
          count: notLive.length,
          color: '#454545',
          title: 'Unassigned Devices',
          list: notLive,
          fullTitle: 'not assigned',
          icon: 'mdi-thumb-up',
          total: Math.floor((notLive.length / this.orgDevicesCorrelatedWithEnrolledPatients.length) * 100),
          description: 'Devices not currently assigned to patients',
        },
      ]
    },
    percentOfDaysRemainingNeeded() {
      let doingGreat = this.enrolledPatients.filter(function (e) {
        return !e.possibleOffTrack && !e.unenrollCandidate
      })

      let unenrollCandidates = this.enrolledPatients.filter(function (e) {
        return e.unenrollCandidate
      })

      let includedPatients = this.enrolledPatients.filter(function (e) {
        return e.possibleOffTrack && !e.unenrollCandidate
      })

      // let zeroTo25 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded <= 70
      // })

      let zeroTo50 = includedPatients.filter(function (e) {
        return e.perfectionNeeded <= 80
      })

      // let twentySixTo50 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 70 && e.perfectionNeeded <= 80
      // })

      // let fiftyOneTo75 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 80 && e.perfectionNeeded <= 90
      // })

      // let seventySixTo100 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 90 && e.perfectionNeeded <= 100
      // })

      let severeRisk = includedPatients.filter(function (e) {
        return e.perfectionNeeded > 80 && e.perfectionNeeded <= 100
      })

      let notAChance = includedPatients.filter(function (e) {
        return e.perfectionNeeded > 100
      })

      let totals = [
        {
          count: doingGreat.length,
          color: '#1A76F2',
          title: 'On Track',
          list: doingGreat,
          fullTitle: 'On Track',
          icon: 'mdi-thumb-up',
          total: Math.floor((doingGreat.length / this.enrolledPatients.length) * 100),
        },
        // {
        //   count: zeroTo25.length,
        //   color: '#FBC02D',
        //   title: 'At Risk',
        //   list: zeroTo25,
        //   fullTitle: 'Concerning',
        //   icon: 'mdi-alert-circle',
        //   total: Math.floor((zeroTo25.length / this.enrolledPatients.length) * 100),
        // },
        {
          count: zeroTo50.length,
          color: '#F9A825',
          title: 'Moderate Risk',
          list: zeroTo50,
          fullTitle: 'Moderate Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((zeroTo50.length / this.enrolledPatients.length) * 100),
        },
        // {
        //   count: fiftyOneTo75.length,
        //   color: '#F57F17',
        //   title: 'Serious Risk',
        //   list: fiftyOneTo75,
        //   fullTitle: 'Serious Risk',
        //   icon: 'mdi-alert-circle',
        //   total: Math.floor((fiftyOneTo75.length / this.enrolledPatients.length) * 100),
        // },
        {
          count: severeRisk.length,
          color: '#E53935',
          title: 'Severe Risk',
          list: severeRisk,
          fullTitle: 'Severe Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((severeRisk.length / this.enrolledPatients.length) * 100),
        },
        {
          count: notAChance.length,
          color: '#424242',
          title: 'Ineligible',
          list: notAChance,
          fullTitle: 'Ineligible',
          icon: 'mdi-alert-octagon',
          total: Math.floor((notAChance.length / this.enrolledPatients.length) * 100),
        },
        {
          count: unenrollCandidates.length,
          color: '#000000',
          title: 'Inactive',
          list: unenrollCandidates,
          fullTitle: 'Inactive',
          icon: 'mdi-close-circle',
          total: Math.floor((unenrollCandidates.length / this.enrolledPatients.length) * 100),
        },
      ]

      return totals
    },
    actuals() {
      let includedPatients = this.enrolledPatients.filter(function (e) {
        return e.possibleOffTrack
      })
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let todayTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfToday.getTime()
      })

      let startOfYesterday = new Date()
      startOfYesterday.setDate(startOfToday.getDate() - 1)
      startOfYesterday.setHours(0, 0, 0, 0)

      let yesterdayTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfYesterday.getTime() && e.mostRecentDataTimestamp < startOfToday
      })

      let startOfSeven = new Date()
      startOfSeven.setDate(startOfToday.getDate() - 7)
      startOfSeven.setHours(0, 0, 0, 0)

      let sevenTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfSeven.getTime() && e.mostRecentDataTimestamp < startOfYesterday
      })

      let startOfThirty = new Date()
      startOfThirty.setDate(startOfToday.getDate() - 30)
      startOfThirty.setHours(0, 0, 0, 0)

      let thirtyTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfThirty.getTime() && e.mostRecentDataTimestamp < startOfSeven
      })

      let moreThanThirty = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp < startOfThirty.getTime()
      })

      let neverTotal = includedPatients.filter(function (e) {
        return !e.mostRecentDataTimestamp
      })

      let totals = [
        {
          count: todayTotal.length,
          color: '#1A76F2',
          title: 'Today',
          list: todayTotal,
          fullTitle: 'Active Today',
        },
        {
          count: yesterdayTotal.length,
          color: '#F9A825',
          title: 'Yesterday',
          list: yesterdayTotal,
          fullTitle: 'Last Active Yesterday',
        },
        {
          count: sevenTotal.length,
          color: '#F57F17',
          title: '3-7 Days',
          list: sevenTotal,
          fullTitle: 'Last Active 3-7 Days Ago',
        },
        {
          count: thirtyTotal.length,
          color: '#F57F17',
          title: '8-30 Days',
          list: thirtyTotal,
          fullTitle: 'Last Active 8-30 Days Ago',
        },
        {
          count: moreThanThirty.length,
          color: '#E53935',
          title: '31+ Days',
          list: moreThanThirty,
          fullTitle: 'Last Active more than 30 Days Ago',
        },
        {
          count: neverTotal.length,
          color: '#000000',
          title: 'Never',
          list: neverTotal,
          fullTitle: 'Never Active',
        },
      ]

      return totals
    },
    chartOptions() {
      const vm = this
      let options = {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                name: {
                  show: false,
                },
                value: {
                  formatter: function (item) {
                    return Math.ceil((item / vm.patientCount) * 100) + '%'
                  },
                },
              },
            },
          },
        },
        chart: {
          type: 'donut',
          events: {
            dataPointSelection: function (_, chartContext, config) {
              vm.selectedCategory = config.dataPointIndex
            },
            dataPointMouseEnter: function (event) {
              event.path[0].style.cursor = 'pointer'
            },
          },
        },
        labels: this.arrayToUse.map(x => x.title),
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: this.arrayToUse.map(x => x.color),
      }
      return options
    },
    chartData() {
      return this.arrayToUse.map(x => x.count)
    },
    patientCount() {
      return this.arrayToUse.map(x => x.count).reduce((a, b) => a + b)
    },
  },
  methods: {
    ...mapActions('rpm', [
      'assignDevice',
      'getPatientIHealthOauthFlow',
      'getPatient',
      'getOrgDevices',
      'getPatientDevices',
    ]),
    exportTheList() {
      createDeviceCSV(
        this.orgDevicesCorrelatedWithEnrolledPatients,
        this.org.name + ' Device List ' + dayjs().format('MM/DD/YYYY')
      )
    },
    cardClick(cat) {
      this.selectedCategory = cat
    },
    graphClick() {},
  },
  async mounted() {
    await this.getOrgDevices(this.org.id)
  },
}
</script>

<style lang="scss" scoped>
.progress-circular-underlay-stroke {
  color: blue;
}
.inactiveClass {
  opacity: 0.6;
}
</style>
